import React from 'react';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import Header from '../components/ui/Header';
import ButtonGroup from '../components/ui/ButtonGroup';
import Button from '../components/ui/Button';
import { StepUrl } from '../utils/enums';
import { getUserType } from '../utils/localStorage/localStorageFunctions';
import SEOLayout from '../components/layouts/SEOLayout';

const style = require('./NotFoundPage.module.scss');

const browser = typeof window !== "undefined" && window;

const NotFoundPage = ({ notRenderHeader }: any) => {
  const handleStartAgain = () => {
    const userType = getUserType();

    if (userType === 'business') {
      navigate(StepUrl.URL_POSTCODE_BUSINESS);
      return;
    }

    navigate(StepUrl.URL_POSTCODE_HOUSEHOLD);
  };

  const handleGoHome = () => {
    window.location.href = 'https://www.huglo.com.au/services/comparison';
  };

  if (!browser) {
    return <SEOLayout />;
  }

  return (
    <div className={classNames('container', style.notFoundPage)}>
      {!notRenderHeader ? <Header style={{ alignSelf: 'flex-start' }} /> : null}
      <h1 className={style.errorCode}>404</h1>
      <h3>Hmm... not sure what happened there.</h3>
      <p>
        The page you&apos;re looking for doesn&apos;t seem to be where it should
        be, or it no longer exists.
      </p>
      <ButtonGroup className="mt-5">
        <Button type="button" variant="tertiary" onClick={handleStartAgain}>
          Start again
        </Button>
        <Button type="button" variant="secondary" onClick={handleGoHome}>
          Go to home
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default NotFoundPage;
